<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about-us">About</router-link> |
    <router-link to="/services">Services</router-link> |
    <router-link to="/case-studies">Case Studies</router-link>
  </div>
  <router-view v-slot="{ Component }">
    <suspense>
      <div>
        <component :is="Component" />
      </div>
    </suspense>
  </router-view>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

a:hover {
  color: #42b983;
}

a {
  cursor: pointer;
}
</style>
