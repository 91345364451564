import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/about-us/team",
    name: "Team",
    component: () => import("../components/about_us/Team.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: () => import("../views/CaseStudies.vue"),
  },
  {
    path: "/case-studies/1",
    name: "Frankford",
    component: () => import("../components/case_studies/Frankford.vue"),
  },
  {
    path: "/case-studies/2",
    name: "Client",
    component: () => import("../components/case_studies/Client.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
